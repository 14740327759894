const env = "prod";

let TOKENS;
if (env === "test" || env === "dev") {
    TOKENS = {
        80001: {
            CONTRACT: "0x0BbacEB66F327E728a8116dA514F82BBB610B5cc",
            SINGLE_COLLECTIBLE_CONTRACT: "0xfF703Ff9E8CCA9840Cc60b9beb88D1b9f37fcbAc",
            MULTIPLE_COLLECTIBLE_CONTRACT: "0xea8478cbDE68d3504419701E1DE307f3B255e342",
            COLLECTION_CREATION_CONTRACT: "0xeCe97AC56e978315CbbFcfa601fE9fC804369b14",
            // COLLECTION_CREATION_CONTRACT : "0xFF9fc69c4B975Aa0F68DE6D03E19af1330873435",
            JSONRPCPROVIDER: "https://rpc-mumbai.maticvigil.com/"
        },
        5: {
            CONTRACT: "0x4C81FF49aDadCA2bAFef34686FA2A79DBDfe6596",
            SINGLE_COLLECTIBLE_CONTRACT: "0x662b73C01bb9FE996B9045c0ae41bEd13b38e4DF",
            MULTIPLE_COLLECTIBLE_CONTRACT: "0x7748a8cCB5c0Bffd086264D60546b4aDfaCC0Ccf",
            COLLECTION_CREATION_CONTRACT: "", //for goerli collection contract not available
            JSONRPCPROVIDER: "https://goerli.infura.io/v3/76c9150bab304d2c9bd9daf8c6c7a15f"
        } , 
        1: {
            CONTRACT: "0xccB76558015c7e2D354b5832bFbCF64657160eA0",
            SINGLE_COLLECTIBLE_CONTRACT: "0x4D4e37000fF802a69f69a51FE7158bb467c93861",
            MULTIPLE_COLLECTIBLE_CONTRACT: "0x3ABcF568cFeff89D35e91071A75F859876CF96ea",
            COLLECTION_CREATION_CONTRACT: "0x1c3a081af343c665916af9ba393443febb3c758e",
            JSONRPCPROVIDER: "https://mainnet.infura.io/v3/76c9150bab304d2c9bd9daf8c6c7a15f"
        },
        137: {
            CONTRACT: "0xccB76558015c7e2D354b5832bFbCF64657160eA0",
            SINGLE_COLLECTIBLE_CONTRACT: "0x4d4e37000ff802a69f69a51fe7158bb467c93861",
            MULTIPLE_COLLECTIBLE_CONTRACT: "0x3ABcF568cFeff89D35e91071A75F859876CF96ea",
            COLLECTION_CREATION_CONTRACT: "0xBd4944177c9BbE4EF13a7FeCd2f31DEba3F228c5",
            JSONRPCPROVIDER: "https://polygon-rpc.com/"
        },
    }
} else if (env === "prod") {
    TOKENS = {
        1: {
            CONTRACT: "0xccB76558015c7e2D354b5832bFbCF64657160eA0",
            SINGLE_COLLECTIBLE_CONTRACT: "0x4D4e37000fF802a69f69a51FE7158bb467c93861",
            MULTIPLE_COLLECTIBLE_CONTRACT: "0x3ABcF568cFeff89D35e91071A75F859876CF96ea",
            COLLECTION_CREATION_CONTRACT: "0x1c3a081af343c665916af9ba393443febb3c758e",
            JSONRPCPROVIDER: "https://mainnet.infura.io/v3/76c9150bab304d2c9bd9daf8c6c7a15f"
        },
        137: {
            CONTRACT: "0xccB76558015c7e2D354b5832bFbCF64657160eA0",
            SINGLE_COLLECTIBLE_CONTRACT: "0x4d4e37000ff802a69f69a51fe7158bb467c93861",
            MULTIPLE_COLLECTIBLE_CONTRACT: "0x3ABcF568cFeff89D35e91071A75F859876CF96ea",
            COLLECTION_CREATION_CONTRACT: "0xBd4944177c9BbE4EF13a7FeCd2f31DEba3F228c5",
            JSONRPCPROVIDER: "https://polygon-rpc.com/"
        },
    }
}


let key = {
};

if (env === "prod") {
    console.log("Set Production Config");
    key = {
        baseUrl: "https://api.pikaso.market/",
        frontUrl: "https://pikaso.market/",
        IMG_URL: "https://api.pikaso.market/static/",
        ipfsurl: "https://ipfs.io/ipfs/",
        ipfslink : "ipfs://",
        options : [ { value: "English", label: "EN" },{ value: "Japanese", label: "日本語" } ],
        Digits: 1000000000000000000,
        toFixed: 5,
        tokens: TOKENS,
        token_name: "PKS",
        app_name: "Pikaso",
        app_logo : "pikaso_logo.png",
        env: env,
    };
} else if (env === "test") {
    key = {
        app_name: "Pikaso",
        baseUrl: "https://pikaso.ceewen.xyz:9001/",
        frontUrl: "https://pikaso.ceewen.xyz/",
        IMG_URL: "https://pikaso.ceewen.xyz:9001/static/",
        ipfsurl: "https://ipfs.io/ipfs/",
        ipfslink : "ipfs://",
        options : [ { value: "English", label: "EN" },{ value: "Japanese", label: "日本語" } ],
        Digits: 1000000000000000000,
        toFixed: 5,
        tokens: TOKENS,
        token_name: "PKS",
        app_logo : "pikaso_logo.png",
        env: env,
    };
} else {
    console.log("Set Development Config");
    key = {
        baseUrl: "http://localhost:9001/",
        frontUrl: "http://localhost:3000/",
        IMG_URL: "http://localhost:9001/static/",
        ipfsurl: "https://ipfs.io/ipfs/",
        ipfslink : "ipfs://",
        options : [ { value: "English", label: "EN" },{ value: "Japanese", label: "日本語" } ],
        Digits: 1000000000000000000,
        toFixed: 5,
        app_name: "Pikaso",
        app_logo : "pikaso_logo.png",
        tokens: TOKENS,
        token_name: "PKS",
        env: env,
    };
}

export default key;