//Modal
export const TOGGLE_SIDE_BAR = "TOGGLE_SIDE_BAR";

//User Register
export const USER_REGISTER_START = "USER_REGISTER_START";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_ERROR = "USER_REGISTER_ERROR";

//Current User
export const SET_CURRENT_USER = "SET_CURRENT_USER";

export const SET_AUTHENTICATION = 'SET_AUTHENTICATION';
export const TOKEN_ID = 'TOKEN_ID';
export const PAYMENT_MDL = 'PAYMENT_MDL';
export const BIT_MODEL = 'BIT_MODEL';
export const TOKEN_BIT_ID = 'TOKEN_BIT_ID';
export const REPORT_MODEL = 'REPORT_MODEL';
export const REPORT_ID = 'REPORT_ID';

export const URI = {
    ETH: "https://mainnet.infura.io/v3/",
    POLYGON: "https://polygon-rpc.com/",
    AVAX: "https://api.avax.network/ext/bc/C/rpc",
    ETH_TEST : "https://goerli.infura.io/v3/",
    POLYGON_TEST : "https://rpc-mumbai.maticvigil.com/",
  };
  
  export const MAINNET_ID = { ETH: 1, POLYGON: 137, AVAX: 43114 , ETH_TEST: 5 , POLYGON_TEST: 80001};
