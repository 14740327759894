import React, { lazy, Suspense } from 'react';
import { Router, Location, Redirect } from '@reach/router';
import ScrollToTopBtn from './components/menu/ScrollToTop';
import { Provider } from "react-redux";
import store from "./store";
import { createGlobalStyle } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import {
    darkTheme,
    RainbowKitProvider
} from "@rainbow-me/rainbowkit";
import { chains } from './constant/wagmi';

const Header = lazy(() => import("./components/menu/header"));
const Footer = lazy(() => import("./components/menu/footer"));
const Home = lazy(() => import("./components/pages/home"));
const Explore = lazy(() => import("./components/pages/explore"));
const Helpcenter = lazy(() => import("./components/pages/helpcenter"));
const Ranking = lazy(() => import("./components/pages/ranking"));
const CollectionDetails = lazy(() => import("./components/pages/CollectionDetails"));
const ItemDetail = lazy(() => import("./components/pages/ItemDetail"));
const Author = lazy(() => import("./components/pages/Author"));
const Works = lazy(() => import("./components/pages/works"));
const CreateNewCollection = lazy(() => import("./components/pages/CreateNewCollection"));
const Ethereum = lazy(() => import("./components/pages/ethereum"));
const Polygon = lazy(() => import("./components/pages/polygon"));
const SingleSelection = lazy(() => import("./components/pages/singleSelection"));
const Search = lazy(() => import("./components/pages/Search"));
const Editprofile = lazy(() => import("./components/pages/Editprofile"));
const PksToken = lazy(() => import("./components/pages/PksToken"));
const AboutUs = lazy(() => import("./components/pages/AboutUs"));
const Art = lazy(() => import("./components/pages/Art"));
const Photography = lazy(() => import("./components/pages/Photography"));
const Meme = lazy(() => import("./components/pages/Meme"));
const Music = lazy(() => import("./components/pages/Music"));
const All = lazy(() => import("./components/pages/All"));
const Collections = lazy(() => import("./components/pages/Collections"));
const EditCollec = lazy(() => import("./components/pages/EditCollec"));



const GlobalStyles = createGlobalStyle`
  :root {
    scroll-behavior: unset;
  }
`;

export const ScrollTop = ({ children, location }) => {
    React.useEffect(() => window.scrollTo(0, 0), [location])
    return children
}

const PosedRouter = ({ children }) => (
    <Location>
        {({ location }) => (
            <div id='routerhang'>
                <div key={location.key}>
                    <Router location={location}>
                        {children}
                    </Router>
                </div>
            </div>
        )}
    </Location>
);

const App = () => (
    <div className="wraper">
        <Provider store={store}>
            <RainbowKitProvider
                appInfo={{
                    appName: 'Pikaso'
                }}
                chains={chains}
                theme={darkTheme({
                    accentColor: '#7b3fe4',
                    accentColorForeground: 'white',
                    borderRadius: 'small',
                    fontStack: 'system',
                    overlayBlur: 'small',
                })}
            >
                <GlobalStyles />
                <ToastContainer />
                <Suspense fallback={<h5>...</h5>}>
                    <Header />
                    <PosedRouter>
                        <ScrollTop path="/">
                            <Home exact path="/">
                                <Redirect to="/home" />
                            </Home>
                            <Search path="/search" />
                            <Explore path="/explore" />
                            <Helpcenter path="/helpcenter" />
                            <Ranking path="/ranking" />
                            <ItemDetail path="/ItemDetail/:collectionName/:token_id" />
                            <PksToken path="/pkstoken" />
                            <Author path="/Author" />
                            <Editprofile path="/editprofile" />
                            <Works path="/how-it-works" />
                            {/* Collections */}
                            <CollectionDetails path="/collections/:collectionName" />
                            <CreateNewCollection path="/create-collection/:user_address" />
                            <EditCollec path="/Edit/:collection_name" />
                            <Collections path="/collections" />
                            {/* <MultiSelection path = "/multiselection/:collection_name" /> */}
                            <SingleSelection path="/singleselection/:collection_name" />
                            {/* <Contact path="/contact" /> */}
                            {/* NFT TYPES */}
                            <All path="/All-Nfts" />
                            <Ethereum path="/ethereum" />
                            <Polygon path="/polygon" />
                            <Art path="/Art-Nfts" />
                            <Photography path="/photos-Nfts" />
                            <Meme path="/meme-Nfts" />
                            <Music path="/music-Nfts" />
                            <AboutUs path="/about-us" />
                        </ScrollTop>
                    </PosedRouter>
                    <Footer />
                    <ScrollToTopBtn />
                </Suspense>
            </RainbowKitProvider>
        </Provider>
    </div>
);
export default App;